<template>
  <b-card>
    <b-modal
      v-model="dialog"
      size="md"
      title="Deposit Momo Manual"
      ok-title="Save"
      header-close-content=""
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-row class="mt-2">
          <label for="phoneInput">User phone:</label>
          <b-row class="mt-1 w-100">
            <input
              id="phoneInput"
              v-model="userPhone"
              name="phoneInput"
              data-vv-as="User phone"
              :class="{ 'col-9': true, input: true, 'is-danger': errors.has('phoneInput') }"
              v-validate="{ required: true }"
            />
            <b-button variant="primary" class="ml-auto col-3" @click="getActivePaymentCode">Select</b-button>
          </b-row>
          <span v-show="errors.has('phoneInput')" class="is-danger-text position-relative">{{
            errors.first('phoneInput')
          }}</span>
        </b-row>
        <b-row v-if="paymentCode" class="mt-2">
          <label for="paymentIdInput">User transaction code</label>
          <input
            id="paymentIdInput"
            v-model="paymentCode"
            name="paymentCodeInput"
            disabled="true"
            :class="{
              input: true,
              'is-danger': errors.has('paymentIdInput')
            }"
            v-validate="{ required: true }"
          />
          <span v-show="errors.has('paymentIdInput')" class="is-danger-text position-relative">{{
            errors.first('paymentIdInput')
          }}</span>
        </b-row>
        <b-row v-if="paymentCode" class="mt-2">
          <label for="amountInput">Deposit Amount:</label>
          <b-form-input
            id="amountInput"
            v-model="amount"
            :formatter="formatPaymentValue"
            name="amount"
            data-vv-as="Amount"
            :class="{
              input: true,
              'is-danger': errors.has('amount')
            }"
            v-validate="{ required: true }"
          />
          <span v-show="errors.has('amount')" class="is-danger-text position-relative">{{
            errors.first('amount')
          }}</span>
        </b-row>
        <b-row v-if="paymentCode" class="mt-2">
          <label for="paymentIdInput">Payment Id:</label>
          <input
            id="paymentIdInput"
            v-model="paymentId"
            name="paymentIdInput"
            data-vv-as="Payment Id"
            :class="{
              input: true,
              'is-danger': errors.has('paymentIdInput')
            }"
            v-validate="{ required: true }"
          />
          <span v-show="errors.has('paymentIdInput')" class="is-danger-text position-relative">{{
            errors.first('paymentIdInput')
          }}</span>
        </b-row>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="6">
        <h4 class="b-card-title">Momo transaction list</h4>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Status" label-for="bundle_name_filter">
          <b-form-select
            id="bundle_name_filter"
            v-model="filterStatusValue"
            :options="filterStatusList"
            @change="getData"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-button variant="primary" class="float-right" @click="dialog = true">Deposit Momo</b-button>
      </b-col>
      <!-- <b-col cols="2">
        <b-form-group label="Payment" label-for="payment_filter">
          <b-form-select
            id="payment_filter"
            v-model="filterPaymentValue"
            :options="filterPaymentList"
            @change="handleChangeFilter"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group label="Status" label-for="status_filter">
          <b-form-select
            id="status_filter"
            v-model="filterStatusValue"
            :options="filterStatusList"
            @change="handleChangeFilterStatus"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>-->
      <!-- Filter UserName -->
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="listLogHistoryData.data.map((item) => ({ ...item, action: '' }))"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table text-center"
      :busy="isLoading"
    >
      <!-- CELL -->
      <template v-slot:cell(amount)="item">{{ item.value.toLocaleString() }}</template>
      <template v-slot:cell(payment_status)="item">
        <b-badge class="mr-1 mb-1" v-if="item.value === 1" variant="success">Done</b-badge>
        <b-badge class="mr-1 mb-1" v-else-if="item.value === 0" variant="warning">Pending</b-badge>
        <b-badge class="mr-1 mb-1" v-else-if="item.value === 2" variant="danger">Failed</b-badge>
        <b-badge class="mr-1 mb-1" v-if="item.item.status === 1" variant="info">Refunded</b-badge>
      </template>

      <!-- <template #cell(format)="item"> </template> -->

      <template #cell(actions)="item">
        <v-icon
          class="text-success"
          :disabled="item.item.payment_status !== 2 || item.item.status === 1"
          @click="updateRefundStatus(item.item)"
          :id="'refund_' + item.item.id"
          >mdi-check</v-icon
        >
        <b-tooltip :target="'refund_' + item.item.id" triggers="hover">Set refunded</b-tooltip>
      </template>

      <template #table-busy>
        <div class="text-center">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">{{ footerTableInfo }}</b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="getData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="listLogHistoryData.total"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="getData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Swal from 'sweetalert2';
import ApiService from '@/common/api.service';
import _ from 'lodash';
const EMPTY_PAYMENT_CODE = '----';
// import TabinTable from './TabinTable';
export default {
  // components: { TabinTable },
  data() {
    return {
      paymentCode: '',
      dialog: false,
      amount: '',
      userPhone: '',
      paymentId: '',
      listLogHistoryData: {
        data: [],
        total: 0
      },

      perPage: 10,
      currentPage: 1,

      isLoading: true,

      filterStatusList: [
        { value: 0, text: 'Pending' },
        { value: 1, text: 'Done' },
        { value: 2, text: 'Failed' }
      ],

      filterStatusValue: null,

      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Message', key: 'message' },
        { text: 'Amount', key: 'amount' },
        { text: 'Notice', key: 'notice' },
        { text: 'Status', key: 'payment_status' },
        { text: 'Action', key: 'actions' }
      ]
    };
  },
  created() {
    this.getData();
  },
  computed: {
    paramsListNotifyHistory() {
      return { params: { limit: this.perPage, offset: this.currentPage, status: this.filterStatusValue } };
    },
    footerTableInfo() {
      const { currentPage, perPage, listLogHistoryData } = this;
      const rows = listLogHistoryData.total;
      return `Showing ${(currentPage - 1) * perPage + 1}-${currentPage * perPage > rows ? rows : currentPage * perPage}
        of ${rows}.`;
    }
  },
  methods: {
    async getActivePaymentCode() {
      if (isNaN(this.userPhone) || this.userPhone.length !== 10) {
        return;
      }

      try {
        const res = await ApiService.get('/payments', `get-reference-code?phone=${this.userPhone}`);
        let data = res.data.data;
        if (data.status) {
          this.paymentCode = data.data.code;
        }
      } catch (errors) {
        if (errors) {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
        }
      }
    },
    formatPaymentValue(value) {
      return this.formatPrice(this.formatNumberFromString(value), '');
    },
    save: _.debounce(function () {
      this.depositMomo();
    }, 500),
    close() {
      this.dialog = false;
      this.amount = '';
      this.userPhone = '';
      this.paymentId = '';
      this.paymentCode = '';
    },
    async depositMomo() {
      if (!this.amount || !this.userPhone || !this.paymentId) {
        Swal.fire({
          icon: 'error',
          title: 'Deposit error',
          showConfirmButton: false,
          timer: 3000
        });
        return;
      }
      const isEmptyCode = this.paymentCode === EMPTY_PAYMENT_CODE;
      const data = {
        paymentTitle: 'Bạn vừa nhận được tiền từ Admin',
        paymentInfo: {
          totalPayment: this.formatNumberFromString(this.amount).toString(),
          userName: '',
          userPhone: this.userPhone,
          paymentTime: new Date().getTime(),
          message: `${this.userPhone}${isEmptyCode ? '' : ' ' + this.paymentCode}`,
          paymentId: this.paymentId
        },
        skip_reference: isEmptyCode
      };
      try {
        const dataRes = await ApiService.post(`/deposits/momo-mail`, data);
        const { status } = dataRes;

        if (status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Deposit success',
            showConfirmButton: false,
            timer: 3000
          });
          this.getData();
        }
      } catch (e) {
        if (status === 403) {
          Swal.fire({
            icon: 'error',
            title: 'Permission denied',
            showConfirmButton: false,
            timer: 3000
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Deposit error',
            showConfirmButton: false,
            timer: 3000
          });
        }
      }
      this.close();
    },
    async updateRefundStatus(item) {
      try {
        const dataRes = await ApiService.patch(`/payment/transaction-momo-history/${item.id}`);
        const { status } = dataRes;
        if (status === 204) {
          item.status = 1;
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: 'Action fail',
          showConfirmButton: false,
          timer: 3000
        });
      }
    },
    handleFilterTableHead(key, value) {
      this.filterTableHead[key] = value;
    },
    async getData() {
      this.isLoading = true;
      try {
        const dataRes = await ApiService.query('/payment/transaction-momo-history', this.paramsListNotifyHistory).then(
          (res) => res.data
        );
        const { status, data } = dataRes;
        if (status) {
          this.listLogHistoryData = data;
        }
      } catch (e) {
        Swal.fire({
          icon: 'error',
          title: "Can't load data! Try again!",
          showConfirmButton: false,
          timer: 3000
        });
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
input {
  width: 100%;
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #9aabff;
    outline: 0;
  }
}
</style>
>
